import React, { useState , useEffect} from 'react';

//COMPONENTS
import withStyles from '@material-ui/core/styles/withStyles';
import { Text, Loader } from 'components/atoms';

const styles = theme => {
  return {
    layout: {
      width: 'auto',
      display: 'block', // Fix IE11 issue.
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    paper: {
      marginTop: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  };
};

const LineAppLogin = ({ classes }) => {
  useEffect(() => {
    const url = new URL(window.location.href);
    const host = url.searchParams.get('host');
    window.location = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1606766442&redirect_uri=${host}/api/auth/line&state=app_login&scope=openid email profile`;
  }, []);
  return (
    <div className={classes.layout}>
      <div className={classes.paper}>
        <Text style={{ marginTop: '50%' }} fontWeight={'bold'}>
          กำลังเชื่อมต่อไปยัง Line Application
        </Text>
        <Loader />
      </div>
    </div>
  );
};

export default withStyles(styles)(LineAppLogin);
